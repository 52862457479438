<template>
  <Container>
    <p class="confirm-reset-password__content text-center">
      Create your new password below
    </p>
    <v-form
      class="mt-8"
      @submit.prevent="confirmResetPassword">
      <label class="sourcery__form">
        Code
        <v-text-field
          v-model="password.code"
          :append-icon="!!verificationCodeErrors.length? 'mdi-alert-circle-outline' : ''"
          :error-messages="verificationCodeErrors"
          :error="!!verificationCodeErrors.length"
          :height="48"
          autocomplete="one-time-code"
          class="mt-2"
          dense
          outlined
          single-line
          @input="errorWithCode = null" />
      </label>

      <label class="sourcery__form">
        New Password
        <v-text-field
          v-model="password.newPassword"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :error="!!newPasswordErrors.length"
          :error-messages="newPasswordErrors"
          :height="48"
          :type="showNewPassword ? 'text' : 'password'"
          autocomplete="new-password"
          class="mt-2"
          name="new password"
          dense
          outlined
          single-line
          @click:append="showNewPassword = !showNewPassword" />
      </label>

      <label class="sourcery__form">
        Confirm Password
        <v-text-field
          v-model="password.repeatPassword"
          :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :error="!!repeatPasswordErrors.length"
          :error-messages="repeatPasswordErrors"
          :height="48"
          :type="showConfirmPassword ? 'text' : 'password'"
          autocomplete="new-password"
          class="mt-2"
          dense
          outlined
          single-line
          @click:append="showConfirmPassword = !showConfirmPassword" />
      </label>

      <v-btn
        :elevation="0"
        class="white--text sourcery__big-pill"
        color="blue"
        type="submit"
        block>
        Reset Password
      </v-btn>
    </v-form>
  </Container>
</template>

<script>
import { Auth } from 'aws-amplify';
import {
  required,
  sameAs,
  minLength,
} from 'vuelidate/lib/validators';
import { mapMutations } from 'vuex';
import ErrorsText from '@/constants/errors';
export default {
  name: 'WhiteLabelConfirmResetPassword',
  components: {
    Container: () => import('@/views/Agent/Container'),
  },
  props: {
    email: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    errorWithCode: null,
    showNewPassword: false,
    showConfirmPassword: false,
    password: {
      code: null,
      newPassword: null,
      repeatPassword: null,
    },
  }),
  validations: {
    password: {
      newPassword: {
        required, minLength: minLength(8),
      },
      repeatPassword: {
        required, sameAs: sameAs('newPassword'),
      },
      code: {
        required, minLength: minLength(6),
      },
    },
  },
  computed: {
    verificationCodeErrors() {
      const errors = [];
      if (!this.$v.password.code.$dirty) return errors;
      if (!this.$v.password.code.minLength) {
        errors.push('Verification code must be at 6 characters long');
      }
      if (!this.$v.password.code.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.errorWithCode) {
        errors.push(this.errorWithCode);
      }
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.password.newPassword.$dirty) return errors;
      if (!this.$v.password.newPassword.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (!this.$v.password.newPassword.minLength) {
        errors.push('Password must be with 8 characters long');
      }
      return errors;
    },
    repeatPasswordErrors() {
      const errors = [];
      if (!this.$v.password.repeatPassword.$dirty) return errors;
      if (!this.$v.password.repeatPassword.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (!this.$v.password.repeatPassword.sameAs) {
        errors.push('Confirm Password must be the same');
      }
      return errors;
    },
  },
  created() {
    if (!this.email) {
      this.$router.push('white-label-forgot');
    }
  },
  methods: {
    ...mapMutations(['spinner']),
    async confirmResetPassword() {
      this.$v.password.$touch();
      if (!this.$v.password.$invalid) {
        const { email } = this;
        const { code, newPassword } = this.password;
        this.spinner(true);
        try {
          await Auth.forgotPasswordSubmit(email, code, newPassword);
          const { origin } = window.location;
          window.open(`${origin}/white-label?email=${encodeURIComponent(email)}&password=${encodeURIComponent(newPassword)}`, '_blank');
          this.spinner(false);
          this.$router.push({
            name: 'white-label',
          });
        } catch (err) {
          this.spinner(false);
          this.errorWithCode = err.message;
        }
      }
    },
  },
};
</script>